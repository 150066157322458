import { createSignal, For, Match, Show, Switch } from "solid-js";
import c from "class-c";

import Api from "@repo/api";
import Button from "@repo/ui/buttons/Button";
import ContainerButton from "@repo/ui/buttons/ContainerButton";
import Card from "@repo/ui/containers/Card";
import Pdf from "@repo/ui/files/Pdf";
import Detail from "@repo/ui/info/Detail";
import ListTile from "@repo/ui/info/ListTile";
import TextInput from "@repo/ui/inputs/TextInput";
import Constraints from "@repo/ui/layout/Constraints";
import Masonry from "@repo/ui/layout/Masonry";
import Address from "@repo/utils/Address";
import FullName from "@repo/utils/FullName";

import { useUsers } from "~/state/features/users";

import styles from "./manual-idv.module.scss";

export default function ManualIdvPage() {
  const [users, setUsers] = useUsers();
  const [failureReason, setFailureReason] = createSignal("");

  return (
    <Constraints class="p-3">
      <Masonry minColumnWidth={300} class="column">
        <For
          each={users.pendingManualIdvAttempts()}
          fallback={<div>No pending reviews.</div>}
        >
          {(pendingManualIdvAttempt) => {
            const [show, setShow] = createSignal(false);

            return (
              <Card outline>
                <ContainerButton
                  onClick={() => {
                    setShow(!show());
                  }}
                >
                  <ListTile
                    title={pendingManualIdvAttempt.userId}
                    trailing={show() ? "" : ""}
                  ></ListTile>
                </ContainerButton>

                <Show when={show()}>
                  <Detail.PropsProvider variant="stacked">
                    <Detail label="Name">
                      {FullName.format(pendingManualIdvAttempt.name)}
                    </Detail>
                    <Detail label="Address">
                      {Address.format(pendingManualIdvAttempt.address)}
                    </Detail>
                    <Detail label="Birth date">
                      {pendingManualIdvAttempt.birthDate}
                    </Detail>
                  </Detail.PropsProvider>

                  <div class="row pb-1">
                    <div
                      class={c`${styles.image} text:small color:moderate-weak`}
                    >
                      Photo ID
                    </div>
                    <div
                      class={c`${styles.image} text:small color:moderate-weak`}
                    >
                      Selfie with photo ID
                    </div>
                  </div>

                  <div class="row">
                    <Switch>
                      <Match
                        when={
                          pendingManualIdvAttempt.photoIdMimeType ===
                          "application/pdf"
                        }
                      >
                        <div class={styles.image}>
                          <Pdf
                            url={`${Api.origin}${pendingManualIdvAttempt.photoIdUrl}`}
                          />
                        </div>
                      </Match>
                      <Match
                        when={
                          pendingManualIdvAttempt.photoIdMimeType.slice(
                            0,
                            6,
                          ) === "image/"
                        }
                      >
                        <img
                          class={styles.image}
                          src={`${Api.origin}${pendingManualIdvAttempt.photoIdUrl}`}
                        />
                      </Match>
                    </Switch>
                    <Switch>
                      <Match
                        when={
                          pendingManualIdvAttempt.selfieMimeType ===
                          "application/pdf"
                        }
                      >
                        <div class={styles.image}>
                          <Pdf
                            url={`${Api.origin}${pendingManualIdvAttempt.selfieUrl}`}
                          />
                        </div>
                      </Match>
                      <Match
                        when={
                          pendingManualIdvAttempt.selfieMimeType.slice(0, 6) ===
                          "image/"
                        }
                      >
                        <img
                          class={styles.image}
                          src={`${Api.origin}${pendingManualIdvAttempt.selfieUrl}`}
                        />
                      </Match>
                    </Switch>
                  </div>

                  <div class="pt-2">
                    <Button
                      variant="primary-soft"
                      size="standard"
                      onClick={() => {
                        setUsers.passManualIdvAttempt(
                          pendingManualIdvAttempt.userId,
                        );
                      }}
                    >
                      Pass
                    </Button>
                    <Button
                      variant="primary-soft"
                      size="standard"
                      disabled={!failureReason()}
                      onClick={() => {
                        setUsers.failManualIdvAttempt(
                          pendingManualIdvAttempt.userId,
                          failureReason(),
                        );
                      }}
                    >
                      Fail
                    </Button>
                    <Button
                      variant="primary-soft"
                      size="standard"
                      disabled={!failureReason()}
                      onClick={() => {
                        setUsers.manualIdvAttemptNeedsReupload(
                          pendingManualIdvAttempt.userId,
                          failureReason(),
                        );
                      }}
                    >
                      Needs reupload
                    </Button>
                    <TextInput
                      label="Failure reason"
                      onInput={(e) => {
                        setFailureReason(e.currentTarget.value);
                      }}
                    />
                  </div>
                </Show>
              </Card>
            );
          }}
        </For>
      </Masonry>
    </Constraints>
  );
}
